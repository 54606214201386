import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import { Color, Spacing } from "../../../../tokens";

type Props = {
  heading: string;
  className?: string;
};

const Heading: React.FC<Props> = ({ heading, className }) => {
  return (
    <p css={headingStyle} className={className}>
      {heading}
    </p>
  );
};

const headingStyle = css`
  font-size: ${text.size[16]};
  font-weight: bold;
  padding-left: ${Spacing[12]};
  position: relative;
  &:before {
    display: block;
    content: "";
    background: ${Color.Neutral.Base.Primary};
    width: 4px;
    height: 18px;
    position: absolute;
    left: 0;
    top: calc(50% - 9px);
  }
`;

export default Heading;
