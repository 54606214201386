import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import {
  faCloudUploadAlt,
  faRotate,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { backgroundImage } from "../../../../styles/mixin";
import { Color, LineHeight, Spacing, RadiusSize } from "../../../../tokens";

type Props = {
  label: string;
  supplement?: string;
  displayImageUrl?: string;
  isRounded?: boolean;
  backgroundSize?: React.CSSProperties["backgroundSize"];
  onLoadImageData?: (imageDataUrl: string) => void;
  onChangeImageFile?: (file: File) => void;
  onDeleteImageUrl?: () => void;
} & Omit<JSX.IntrinsicElements["input"], "type" | "onChange">;

const UploadImage: React.FC<Props> = ({
  name,
  label,
  supplement,
  displayImageUrl,
  backgroundSize,
  isRounded,
  className,
  onLoadImageData,
  onChangeImageFile,
  onDeleteImageUrl,
  ...inputProps
}) => {
  return (
    <div css={wrapperStyle} className={className}>
      <label
        htmlFor={name}
        css={innerStyle(displayImageUrl, isRounded, backgroundSize)}
      >
        <input
          {...inputProps}
          name={name}
          type="file"
          css={inputStyle}
          onChange={(e) => {
            const callbacks = { onLoadImageData, onChangeImageFile };
            loadFile(e, callbacks);
            (e.target as HTMLInputElement).value = "";
          }}
        />
        {displayImageUrl ? (
          <div css={wrapprtEditStyle}>
            <div css={editStyle} className="a-uploadImage__edit">
              <FontAwesomeIcon css={editIconStyle} icon={faRotate} />
              変更
            </div>
          </div>
        ) : (
          <div>
            <FontAwesomeIcon css={uploadIconStyle} icon={faCloudUploadAlt} />
            <p css={labelStyle}>{label}</p>
            {supplement && <p css={supplementStyle}>{supplement}</p>}
          </div>
        )}
      </label>
      {onDeleteImageUrl && displayImageUrl && (
        <button
          css={deleteStyle}
          onClick={(e) => {
            e.preventDefault();
            onDeleteImageUrl && onDeleteImageUrl();
          }}
        >
          <FontAwesomeIcon css={deleteIconStyle} icon={faXmark} />
        </button>
      )}
    </div>
  );
};

const loadFile = (
  e: React.ChangeEvent<HTMLInputElement>,
  callbacks: {
    onLoadImageData?: (imageDataUrl: string) => void;
    onChangeImageFile?: (file: File) => void;
  },
) => {
  const fileReader = new FileReader();
  fileReader.onloadend = (r) => {
    const result = r.target?.result;
    if (result && typeof result === "string" && callbacks.onLoadImageData) {
      callbacks.onLoadImageData(result);
    }
  };
  const file =
    e.currentTarget.files && e.currentTarget.files.length > 0
      ? e.currentTarget.files[0]
      : null;
  if (file) {
    fileReader.readAsDataURL(file);
    if (callbacks.onChangeImageFile) {
      callbacks.onChangeImageFile(file);
    }
  }
};

const wrapperStyle = css`
  position: relative;
`;

const innerStyle = (
  displayImageUrl?: string,
  isRounded?: boolean,
  backgroundSize?: React.CSSProperties["backgroundSize"],
) => css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${isRounded ? RadiusSize[999] : 0};
  background-color: ${Color.White};
  border: 1px solid ${Color.Neutral.Light.Primary};
  text-align: center;
  cursor: pointer;
  color: ${Color.Primary.Base};
  ${backgroundImage(backgroundSize || "contain", displayImageUrl)}
  box-shadow: none;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    .a-uploadImage__edit {
      background: ${Color.Opacity[80]};
    }
  }
`;

const wrapprtEditStyle = css`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const editStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${Spacing[8]} 0;
  color: ${Color.White};
  background: ${Color.Opacity[60]};
  font-size: ${text.size[12]};
  transition: all 0.1s ease-in;
`;

const editIconStyle = css`
  margin-right: ${Spacing[4]};
`;

const deleteStyle = css`
  position: absolute;
  top: ${Spacing[8]};
  right: ${Spacing[8]};
  display: flex;
  padding: ${Spacing[4]};
  color: ${Color.White};
  background: ${Color.Opacity[60]};
  transition: all 0.1s ease-in;
  border-radius: ${RadiusSize[999]};
  &:hover {
    cursor: pointer;
    background: ${Color.Opacity[80]};
  }
`;

const deleteIconStyle = css`
  min-width: 16px;
  font-size: ${text.size[16]};
`;

const inputStyle = css`
  display: none;
`;

const uploadIconStyle = css`
  display: block;
  margin: 0 auto ${Spacing[8]};
  font-size: ${text.size[32]};
`;

const labelStyle = css`
  line-height: ${LineHeight[140]};
  font-size: ${text.size[12]};
`;

const supplementStyle = css`
  line-height: ${LineHeight[140]};
  font-size: ${text.size[12]};
  color: ${Color.Neutral.Base.Secondary};
`;

export default UploadImage;
