import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { Color, RadiusSize, Shadow, Spacing, ZIndex } from "../../../../tokens";

export const selectboxMultipleStyles = {
  buttonStyle: (
    isDisabled?: boolean,
    isError?: boolean,
    isFit?: boolean,
  ) => css`
    position: relative;
    width: ${isFit ? "auto" : "100%"};
    background: ${isDisabled ? Color.Neutral.Light.Secondary : Color.White};
    color: ${isDisabled
      ? Color.Neutral.Base.Secondary
      : Color.Neutral.Base.Primary};
    border: 1px solid
      ${isError ? Color.Attention.Caution.Base : Color.Neutral.Light.Primary};
    border-radius: ${RadiusSize[2]};
    box-sizing: border-box;
    cursor: ${isDisabled ? "not-allowed" : "pointer"};
    padding: ${Spacing[4]} ${Spacing[8]};
    text-align: left;
  `,

  labelStyle: css`
    display: block;
    color: ${Color.Neutral.Base.Secondary};
    font-size: ${text.size[12]};
    line-height: 20px;
  `,

  textStyle: css`
    font-size: ${text.size[14]};
    padding-right: ${Spacing[16]};
    line-height: 22px;
  `,

  arrowIconStyle: css`
    position: absolute;
    right: 9px;
    bottom: 12px;
  `,

  menuStyle: css`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: ${ZIndex.PullDown};
    width: 100%;
    min-width: 120px;
    background: ${Color.White};
    border-radius: ${RadiusSize[2]};
    border: solid 1px ${Color.Neutral.Light.Primary};
    box-shadow: ${Shadow.Box[1]};
    padding: ${Spacing[4]} 0;
    max-height: 240px;
    overflow-y: auto;
  `,

  checkboxStyle: css`
    padding: ${Spacing[8]} ${Spacing[12]};
    font-size: ${text.size[12]};
    width: 100%;
    &:hover {
      background: ${Color.Neutral.Pale.Primary};
    }
  `,
};
