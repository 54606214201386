import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseControllerProps,
  useWatch,
} from "react-hook-form";
import { RHFCheckbox } from "../Checkbox";
import { selectboxMultipleStyles } from "./styles";

export type RHFSelectboxMultipleItem<
  FV extends FieldValues,
  N extends FieldPath<FV>,
> = {
  label: string;
  value: FieldPathValue<FV, N>[number];
};

export type RHFSelectboxMultipleProps<
  FV extends FieldValues,
  N extends FieldPath<FV>,
> = {
  label?: string;
  defaultText: string;
  isDisabled?: boolean;
  isFit?: boolean;
  testId?: string;

  options: RHFSelectboxMultipleItem<FV, N>[];
} & UseControllerProps<FV, N>;

export const genSelectMultipleOptionTestId = (
  testId = "",
  optionValue: string,
) => `${testId}_${optionValue}`;

export function RHFSelectboxMultiple<
  FV extends FieldValues,
  N extends FieldPath<FV>,
>({
  control,
  name,
  label,
  defaultText,
  isDisabled,
  isFit,
  testId,
  options,
}: RHFSelectboxMultipleProps<FV, N>) {
  const [openMenu, toggleMenu] = useState(false);
  const values = useWatch({ control, name });

  const optionValues = useMemo(() => {
    if (!Array.isArray(values)) return [];
    return options.filter((option) => values.includes(option.value));
  }, [values, options]);

  const selectedLabel = useMemo(() => {
    if (optionValues.length === 0) return defaultText;
    return optionValues.map((optionValue) => optionValue.label).join(", ");
  }, [optionValues, defaultText]);

  return (
    <button
      css={selectboxMultipleStyles.buttonStyle(isDisabled, isFit)}
      onClick={() => toggleMenu(!openMenu)}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          toggleMenu(false);
        }
      }}
      data-testid={testId}
    >
      {label && <label css={selectboxMultipleStyles.labelStyle}>{label}</label>}
      <p css={selectboxMultipleStyles.textStyle}>{selectedLabel}</p>
      <FontAwesomeIcon
        css={selectboxMultipleStyles.arrowIconStyle}
        icon={faSortDown}
      />
      {openMenu && (
        <ul css={selectboxMultipleStyles.menuStyle}>
          {options.map((option, index) => {
            return (
              <li
                key={index}
                onClick={(e) => {
                  // TODO: stopPropagationを消してDom構造を修正する
                  // JIRA: https://nabra-estie.atlassian.net/browse/FL-914
                  e.stopPropagation();
                }}
              >
                <RHFCheckbox
                  control={control}
                  name={name}
                  value={option.value}
                  css={selectboxMultipleStyles.checkboxStyle}
                  multiple
                  testId={genSelectMultipleOptionTestId(testId, option.value)}
                >
                  {option.label}
                </RHFCheckbox>
              </li>
            );
          })}
        </ul>
      )}
    </button>
  );
}
