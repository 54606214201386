import { css } from "@emotion/react";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Checkbox from "../Checkbox";
import { selectboxMultipleStyles } from "./styles";

export type SelectboxMultipleProps = {
  label?: string;
  defaultText: string;
  checkedTexts: string[];
  checkboxName: string;
  checkboxes: SelectboxMultipleItem[];
  isDisabled?: boolean;
  isError?: boolean;
  isFit?: boolean;
  testId?: string;
  hasEllipsis?: boolean;
};

export type SelectboxMultipleItem = {
  label: string;
  isSelected?: boolean;
  onClickCheckBox?: () => void;
};

export const SelectboxMultiple: React.FC<SelectboxMultipleProps> = ({
  label,
  defaultText,
  checkedTexts,
  checkboxName,
  checkboxes,
  isDisabled,
  isError,
  isFit,
  testId,
  hasEllipsis = false,
}) => {
  const [openMenu, toggleMenu] = useState(false);
  return (
    <button
      type="button"
      css={selectboxMultipleStyles.buttonStyle(isDisabled, isError, isFit)}
      onClick={() => toggleMenu(!openMenu)}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          toggleMenu(false);
        }
      }}
      data-testid={testId}
    >
      {label && <label css={selectboxMultipleStyles.labelStyle}>{label}</label>}
      <p
        // ビル一覧で必要なため、一時的に CSS を追加
        // TODO: プルダウン内のフィルター対応の際にここも見直す
        css={[
          selectboxMultipleStyles.textStyle,
          hasEllipsis &&
            css`
              overflow: hidden;
              max-width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
            `,
        ]}
      >
        {checkedTexts.length > 0 ? checkedTexts.join(", ") : defaultText}
      </p>
      <FontAwesomeIcon
        css={selectboxMultipleStyles.arrowIconStyle}
        icon={faSortDown}
      />
      {openMenu && (
        <ul css={selectboxMultipleStyles.menuStyle}>
          {checkboxes.map((item: SelectboxMultipleItem, index) => {
            return (
              <li
                key={index}
                onClick={(e) => {
                  // TODO: stopPropagationを消してDom構造を修正する
                  // JIRA: https://nabra-estie.atlassian.net/browse/FL-914
                  e.stopPropagation();
                }}
              >
                <Checkbox
                  name={checkboxName}
                  css={selectboxMultipleStyles.checkboxStyle}
                  isChecked={item.isSelected}
                  onToggle={item.onClickCheckBox}
                  testId={`${testId}_${item.label}`}
                >
                  {item.label}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      )}
    </button>
  );
};
