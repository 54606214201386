import {
  GenerateAskingPresignedUrlDocument,
  GenerateBuildingPresignedUrlDocument,
  GenerateLessorUserPresignedUrlDocument,
  GraphQLGenerateAskingPresignedUrlInput,
  GraphQLGenerateBuildingPresignedUrlInput,
  GraphQLGenerateLessorUserPresignedUrlInput,
  urqlClient,
} from "../../graphql";

const uploadImageFileToS3 = async (
  presignedUrl: string,
  acl: string,
  file: File,
) => {
  const blob = new Blob([file], { type: file.type });
  await fetch(presignedUrl, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": blob.type,
      "x-amz-acl": acl,
    },
    body: blob,
  });

  // remove query parameters
  const urls = presignedUrl.split("?");
  return urls[0];
};

export const uploadLessorUserImageFile = async (
  input: GraphQLGenerateLessorUserPresignedUrlInput,
  file: File,
) => {
  const { data } = await urqlClient
    .mutation(GenerateLessorUserPresignedUrlDocument, { input })
    .toPromise();
  if (!data) throw new Error("Failed to create presigned URL for lessor user");
  const { presignedUrl, acl } = data.generateLessorUserPresignedUrl;
  const url = await uploadImageFileToS3(presignedUrl, acl, file);
  return url;
};

export const uploadBuildingImageFile = async (
  input: GraphQLGenerateBuildingPresignedUrlInput,
  file: File,
) => {
  const { data } = await urqlClient
    .mutation(GenerateBuildingPresignedUrlDocument, { input })
    .toPromise();
  if (!data) throw new Error("Failed to create presigned URL for building");
  const { presignedUrl, acl } = data.generateBuildingPresignedUrl;
  const url = await uploadImageFileToS3(presignedUrl, acl, file);
  return url;
};

export const uploadAskingImageFile = async (
  input: GraphQLGenerateAskingPresignedUrlInput,
  file: File,
) => {
  const { data } = await urqlClient
    .mutation(GenerateAskingPresignedUrlDocument, { input })
    .toPromise();
  if (!data) throw new Error("Failed to create presigned URL for asking");
  const { presignedUrl, acl } = data.generateAskingPresignedUrl;
  const url = await uploadImageFileToS3(presignedUrl, acl, file);
  return url;
};
