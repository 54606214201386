import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { forwardRef, useCallback } from "react";
import { placeholderColor } from "../../../../styles/mixin";
import { Color, Spacing, RadiusSize } from "../../../../tokens";

type ChangeHanlder =
  | {
      isRHF: true;
      onChange: JSX.IntrinsicElements["textarea"]["onChange"];
    }
  | {
      isRHF?: false;
      onChange?: (value: string) => void;
    };

export type TextareaProps = {
  label?: string;
  height?: string;
  isRequired?: boolean;
  isReadonly?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  testId?: string;
} & Omit<
  JSX.IntrinsicElements["textarea"],
  "required" | "disabled" | "readOnly" | "onChange"
> &
  ChangeHanlder;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    {
      id,
      label,
      height,
      isRequired,
      isReadonly,
      isDisabled,
      isError,
      onChange,
      className,
      testId,
      isRHF,
      ...textareaProps
    },
    ref,
  ) {
    const onChangeInput = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (!onChange) return;
        if (isRHF) {
          onChange(e);
        } else {
          onChange(e.currentTarget.value);
        }
      },
      [onChange, isRHF],
    );

    return (
      <div css={textarea(isError)} className={className}>
        {label && (
          <label htmlFor={id} css={styledLabel}>
            {label}
          </label>
        )}
        <textarea
          {...textareaProps}
          id={id}
          ref={ref}
          css={form(height)}
          className="m-textAreaWithLabel__textarea"
          onChange={onChangeInput}
          readOnly={isReadonly}
          disabled={isDisabled}
          required={isRequired}
          data-testid={testId}
        />
      </div>
    );
  },
);

const textarea = (isError?: boolean) => css`
  padding: ${Spacing[4]} ${Spacing[8]};
  background: ${Color.White};
  border: 1px solid
    ${isError ? Color.Attention.Caution.Base : Color.Neutral.Light.Primary};
  border-radius: ${RadiusSize[2]};
  box-sizing: border-box;
  text-align: left;
`;

const styledLabel = css`
  display: block;
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[12]};
  line-height: 20px;
`;

const form = (height?: string) => css`
  margin: 0;
  padding: 0;
  width: 100%;
  height: ${height ? height : "120px"};
  border: none;
  font-size: ${text.size[14]};
  line-height: 22px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  ${placeholderColor(Color.Neutral.Light.Primary)}
`;
