import {
  SelectboxMultiple,
  SelectboxMultipleItem,
  SelectboxMultipleProps,
} from "./base";

export type { SelectboxMultipleProps, SelectboxMultipleItem };
export default SelectboxMultiple;

export * from "./RHFSelectboxMultiple";
