import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, forwardRef, useCallback } from "react";
import { inlineFlexAlignItemsCenter } from "../../../../styles/mixin";
import { Color, Spacing } from "../../../../tokens";

export type CheckboxProps = {
  isChecked?: boolean;
  isRequired?: boolean;
  isError?: boolean;
  iconCircled?: boolean;
  color?: React.CSSProperties["color"];
  children?: string;
  onToggle?: () => void;
  testId?: string;
} & Omit<JSX.IntrinsicElements["input"], "required" | "checked" | "onChange"> &
  ChangeHandler;

type ChangeHandler =
  | {
      isRHF: true;
      onToggle?: undefined;
      onChange: JSX.IntrinsicElements["input"]["onChange"];
    }
  | {
      isRHF?: false;
      onToggle?: () => void;
      onChange?: undefined;
    };

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  function Checkbox(
    {
      children,
      isChecked,
      isRequired,
      isError,
      iconCircled,
      color,
      onToggle,
      className,
      testId,
      isRHF,
      onChange,
      ...inputProps
    },
    ref,
  ) {
    const onChangeInput = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (isRHF) {
          onChange && onChange(e);
        } else {
          onToggle && onToggle();
        }
      },
      [onToggle, isRHF, onChange],
    );

    return (
      <div css={checkbox} className={className}>
        <input
          {...inputProps}
          ref={ref}
          type="checkbox"
          checked={isChecked}
          onChange={onChangeInput}
          required={isRequired}
          css={input}
          data-testid={testId}
        />
        <span
          css={icon(color, isChecked, isError, iconCircled)}
          className="a-checkbox__icon"
        >
          <FontAwesomeIcon css={iconCnt} icon={faCheck} />
        </span>
        {children && <label css={label}>{children}</label>}
      </div>
    );
  },
);

const checkbox = css`
  overflow: hidden;
  position: relative;
  ${inlineFlexAlignItemsCenter}
  font-size: 0;
  cursor: pointer;
`;

const input = css`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: none !important;
  opacity: 0;
  cursor: pointer;
  &:checked {
    + .a-checkbox__icon {
      background-color: ${Color.Primary.Base};
      border-width: 0;
    }
  }
`;

const icon = (
  color?: React.CSSProperties["color"],
  isChecked?: boolean,
  isError?: boolean,
  iconCircled?: boolean,
) => css`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  background-color: ${isChecked ? color : Color.White};
  border: 2px solid
    ${isError ? Color.Attention.Caution.Base : Color.Neutral.Light.Primary};
  border-radius: 3px;
  color: ${Color.White};
  pointer-events: none;
  flex-shrink: 0;
  border-color: ${color};
  ${iconCircled &&
  css`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border-width: 3px;
  `};
`;

const iconCnt = css`
  font-size: ${text.size[12]};
`;

const label = css`
  position: relative;
  vertical-align: middle;
  pointer-events: none;
  margin-left: ${Spacing[4]};
  font-weight: normal;
  color: ${Color.Neutral.Base.Primary};
  font-size: ${text.size[14]};
  line-height: 20px;
`;
