import { useMemo } from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";
import { Textarea, TextareaProps } from "./base";

type OmitBasePropKeys =
  | keyof UseControllerReturn["field"]
  | keyof UseControllerProps
  | "isError"
  | "ref"
  | "isRHF";

export type RHFTextareProps<FV extends FieldValues> = Omit<
  TextareaProps,
  OmitBasePropKeys
> &
  UseControllerProps<FV>;

export function RHFTextarea<FV extends FieldValues>({
  name,
  control,
  ...props
}: RHFTextareProps<FV>) {
  const { field, fieldState } = useController({ name, control });

  const isError = useMemo(() => {
    return !!fieldState.error;
  }, [fieldState.error]);

  return <Textarea {...props} {...field} isError={isError} isRHF />;
}
