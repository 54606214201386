import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";
import { isEqual } from "../../../../utils/IsEqualObject";
import { Checkbox, CheckboxProps } from "./base";

type OmitBasePropKeys =
  | keyof UseControllerReturn["field"]
  | keyof UseControllerProps
  | "onToggle"
  | "isError"
  | "isChecked"
  | "ref"
  | "isRHF";

type SingleCheckboxValue<FV extends FieldValues, N extends FieldPath<FV>> =
  | (FieldPathValue<FV, N> | undefined)
  | boolean;

type MultipleCheckboxValue<
  FV extends FieldValues,
  N extends FieldPath<FV>,
> = FieldPathValue<FV, N>[];

type CheckboxValue<FV extends FieldValues, N extends FieldPath<FV>> =
  | SingleCheckboxValue<FV, N>
  | MultipleCheckboxValue<FV, N>;

export type RHFCheckboxProps<
  FV extends FieldValues,
  N extends FieldPath<FV>,
> = Omit<CheckboxProps, OmitBasePropKeys> &
  UseControllerProps<FV, N> &
  (
    | {
        multiple: true;
        value: FieldPathValue<FV, N>[number];
        onChange?: (nextState: MultipleCheckboxValue<FV, N>) => void;
      }
    | {
        multiple?: false;
        value?: FieldPathValue<FV, N>;
        onChange?: (nextState: SingleCheckboxValue<FV, N>) => void;
      }
  );

// NOTE: multipleかどうかで動作が分岐する
// multiple === true
//   - value必須
//   - Array<typeof value>の値を持つ
// multiple === false
//   - valueは任意
//   - valueがあるときは typeof value | undefinedの値を持つ
//   - valueがないときは true | falseの値を持つ
export function RHFCheckbox<FV extends FieldValues, N extends FieldPath<FV>>({
  name,
  control,
  children,
  onChange,
  value,
  multiple,
  ...props
}: RHFCheckboxProps<FV, N>) {
  const { field, fieldState } = useController({ name, control });
  const [prevState, setPrevState] = useState<CheckboxValue<FV, N>>(
    multiple ? field.value || [] : field.value,
  );

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPrevState(field.value);

      if (multiple) {
        if (Array.isArray(field.value) && field.value.includes(value)) {
          field.onChange(
            field.value.filter((val: FieldPathValue<FV, N>) => val !== value),
          );
        } else {
          const newValue = field.value ? [...field.value] : [];
          newValue[newValue.length] = value;
          field.onChange(newValue);
        }
      } else {
        if (value) {
          field.onChange(e.target.checked ? value : undefined);
        } else {
          field.onChange(e);
        }
      }
    },
    [field, setPrevState, value, multiple],
  );

  const isChecked = useMemo(() => {
    if (multiple) {
      return field.value ? field.value.includes(value) : false;
    } else {
      if (value) {
        return field.value === value;
      } else {
        return field.value;
      }
    }
  }, [value, field.value, multiple]);

  useEffect(() => {
    if (isEqual(prevState, field.value)) return;
    onChange && onChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevState, field.value]);

  const isError = useMemo(() => {
    return !!fieldState.error;
  }, [fieldState.error]);

  return (
    <Checkbox
      {...props}
      {...field}
      value={value || field.value || ""}
      isChecked={isChecked}
      onChange={_onChange}
      onToggle={undefined}
      isError={isError}
      isRHF
    >
      {children}
    </Checkbox>
  );
}
